const API = "https://apihomologa.geniusws.com.br";
// const API = "https://localhost:44345/";

export const environment = {
  production: true,
  API: API,
  PAYPAL_CLIENT_ID: "", ambiente: "HOMOLOGA",
  THEME: "FRT", //(FRT - ARKBEDS - FRT-BLACK)
  //Módulos de turismo
  USA_CASAMENTO: true,
  USA_CONTRATO: true,
  USA_CIRCUITO: true,
  USA_TURISMO: true,
  USA_PACOTE: true,
  USA_SEGURO: true,
  USA_DISNEY: true,
  USA_CARRO: true,
  USA_AEREO: true,
  USA_LOJA: true,
  USA_CASA: true,
  USA_HOTEL: true,
  USA_BETO: true,
  USA_PROMOCIONAL: true,
  USA_MARKUPIMPRESSAO: false,
  USA_ARKBEDS: false,
  USA_TERMOMETRO: true,
  USA_SOLICITARDESCONTO: true,
  USA_CADASTROAGENCIA: false,
  //Apis dos módulos de turismo
  TURISMO: API,
  TURISMO_AEREO: API,
  TURISMO_HOTEL: API,
  TURISMO_SERVICO: API,
  TURISMO_SEGURO: API,
  GERENCIAMENTO: API,
  TURISMO_CARRO: API,
  EMPRESAS: API,

  //Módulos Adicionais
  USA_BLOQUEIO_FRT: false, // Permite o uso de um botão adicional na aba bloqueios
  USA_INTRANET: true, // Permite o uso da Intranet no portal
  USA_LAMINAS: true, // Permite Gerar Lâminas no portal
  USA_BANNERS: true, //Permite o uso de Banners

  //Configurações
  USA_PACOTESIMPLIFICADO: false,
  CLIENT_ID: "postman:123",
  MAPBOX_ACCESSTOKEN: "pk.eyJ1IjoiZnJ0b3BlcmFkb3JhIiwiYSI6ImNsN3E5a3N0YTAycm00MW8wd2J5NXFoMjIifQ.NDfY7OK_ygHR567e-TVVcw",
  ITENSPORPAGINA: 20,
  MAXIMO_QUARTOS: 8,
  TAG_GOOGLE: "",
  URLSITE: 'https://comprefacil.tur.br'
};
