import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { LoginService } from './login.service';


import { Usuario } from '../model/usuario.model';

import { API, ITENSPORPAGINA } from '../app.config';
import { CLIENT_ID } from '../app.config';

@Injectable()
export class UsuarioService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private login: LoginService
    ) { }

    private usuario: Usuario;

    getUserById(id: number): Observable<any> {
        return this.http.get<any>(
            `${API}/api/usuario/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`
        );
    }

    uploadAssinatura(arquivo: any, id: number): Observable<any> {

        const url = `${API}/api/usuario/assinatura/${id}/`;

        const headers = new HttpHeaders().append('noloader', 'noloader');
        const formData = new FormData();
        formData.append('Arquivo', arquivo);

        return this.http.post(url, formData, { headers: headers });

    }

    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set("noloader", "t");

        if (param.length > 0) {
            return this.http.get<any>(`${API}/api/usuario/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        } else {
            return this.http.get<any>(
                `${API}/api/usuario?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,
                { headers: headers }
            );
        }
    }

    buscarPorParametroPost(valor, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('noloader', 't');
        return this.http.post<any>(`${API}/api/usuario/listpost?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, valor, { headers: headers });

    }

    buscarPorAgencia(
        param: string,
        pagina: Number,
        agenciaId: any
    ): Observable<any> {
        const headers = new HttpHeaders().set("noloader", "t");

        return this.http.get<any>(
            `${API}/api/usuario/poragencia/${agenciaId}/${param.trim()}?Pagina=${pagina}&ItensPorPagina=999`,
            { headers: headers }
        );
    }

    buscarPorEmpresa(
        param: string,
        pagina: Number,
        empresaId: any
    ): Observable<any> {
        const headers = new HttpHeaders().set("noloader", "t");

        return this.http.get<any>(
            `${API}/api/usuario/porempresa/${empresaId}/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,
            { headers: headers }
        );
    }

    alterarSenha(id: number, senha: string, senhaAtual: string): Observable<any> {
        return this.http.patch<any>(`${API}/api/usuario/password/`, {
            Id: id,
            SenhaAtual: senhaAtual,
            Senha: senha,
        });
    }

    desbloquear(id: number): Observable<any> {
        return this.http.patch<any>(`${API}/api/usuario/desbloquear/${id}`, {});
    }

    salvarDados(usuario: Usuario): Observable<Usuario> {
        if (usuario.Id > 0) {
            return this.http.put<Usuario>(
                `${API}/api/usuario/${usuario.Id}`,
                usuario
            );
        } else {
            return this.http.post<any>(`${API}/api/usuario/`, usuario);
        }
    }

    buscarMinhasPermissoes(): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/permissoes/`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${API}/api/usuario/${id}`);
    }

    buscarPerfis(valor: string, tipo: string): Observable<any> {
        let empresa = tipo === "E" ? 1 : 0;
        let agencia = tipo === "A" ? 1 : 0;

        return this.http.get<any>(
            `${API}/api/perfilusuario/list/${valor}?empresa=${empresa}&agencia=${agencia}`
        );
    }

    vincularPerfil(usuarioId: number, perfilId: number): Observable<any> {
        return this.http.put<any>(
            `${API}/api/usuario/${usuarioId}/vincularperfil/${perfilId}`,
            {}
        );
    }

    desvincularPerfil(usuarioId: number, perfilId: number): Observable<any> {
        return this.http.put<any>(
            `${API}/api/usuario/${usuarioId}/desvincularperfil/${perfilId}`,
            {}
        );
    }

    buscarConsultor(agenciaId: number, valor: string, empresaId = 0, reservaId = 0): Observable<any> {
        const headers = new HttpHeaders().set("noloader", "t");
        return this.http.get<any>(
            `${API}/api/usuario/consultor/${agenciaId}/${empresaId}/${reservaId}/${valor}`, { headers: headers }
        );
    }

    buscarAgente(agenciaId: number, valor: string): Observable<any> {
        return this.http.get<any>(
            `${API}/api/usuario/agente/${agenciaId}/${valor}`
        );
    }

    buscarComercialPorParametro(valor: string, empresaId: number = 0) {
        if (valor.length > 0) {
            return this.http.get<any>(
                `${API}/api/usuario/comerciais/${empresaId}/${valor}`
            );
        } else {
            return this.http.get<any>(
                `${API}/api/usuario/comerciais/${empresaId}`
            );
        }
    }

    vincularHotel(usuarioId: number, hotelId: number): Observable<any> {
        return this.http.put<any>(
            `${API}/api/usuario/${usuarioId}/vincularhoteloffline/${hotelId}`,
            {}
        );
    }

    desvincularHotel(usuarioId: number, hotelId: number): Observable<any> {
        return this.http.put<any>(
            `${API}/api/usuario/${usuarioId}/desvincularhoteloffline/${hotelId}`,
            {}
        );
    }

    vincularServicoFornecedor(usuarioId: number, fornecedorId: number): Observable<any> {
        return this.http.put<any>(
            `${API}/api/usuario/${usuarioId}/vincularservicofornecedor/${fornecedorId}`,
            {}
        );
    }

    desvincularServicoFornecedor(usuarioId: number, fornecedorId: number): Observable<any> {
        return this.http.put<any>(
            `${API}/api/usuario/${usuarioId}/desvincularservicofornecedor/${fornecedorId}`,
            {}
        );
    }

    vincularEmpresa(usuarioId: number, empresaId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/${usuarioId}/vincular/${empresaId}`);
    }

    desvincularEmpresa(usuarioId: number, empresaId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/${usuarioId}/desvincular/${empresaId}`);
    }

    vincularGrupo(usuarioId: number, grupoId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/${usuarioId}/vincularGrupo/${grupoId}`);
    }

    desvincularGrupo(usuarioId: number, grupoId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/${usuarioId}/desvincularGrupo/${grupoId}`);
    }

    singleSignOn(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/singlesignon/${id}`, {headers: new HttpHeaders().set('noloader', 'noloader')});
    }
}
